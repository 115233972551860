var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('h2',{staticClass:"auth-layout--title"},[_vm._v(_vm._s(_vm.$t('menu.login')))]),_c('app-form',{attrs:{"hide-cancel":"","loading":_vm.loading},on:{"submit":_vm.onSubmit},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","type":"submit","width":"100%","disabled":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('btn.login'))+" ")])]},proxy:true}])},[_c('app-input',{attrs:{"name":"username","rules":"required","label":_vm.$t('fields.username'),"binds":{ singleLine: true, prependIcon: 'mdi-account' }},model:{value:(_vm.formData.username),callback:function ($$v) {_vm.$set(_vm.formData, "username", $$v)},expression:"formData.username"}}),_c('app-input',{attrs:{"name":"password","rules":"required","type":_vm.viewPassword ? 'text' : 'password',"label":_vm.$t('fields.password'),"binds":{
        singleLine: true,
        prependIcon: 'mdi-lock',
        appendIcon: _vm.viewPassword ? 'mdi-eye' : 'mdi-eye-off'
      },"events":{
        'click:append': () => (_vm.viewPassword = !_vm.viewPassword)
      }},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})],1),_c('div',{staticClass:"auth-layout--footer"},[_c('router-link',{attrs:{"to":{ name: 'forget-password' }}},[_vm._v(" "+_vm._s(_vm.$t('label.forgetPassword'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }