<template>
  <div class="login">
    <h2 class="auth-layout--title">{{ $t('menu.login') }}</h2>
    <app-form hide-cancel @submit="onSubmit" :loading="loading">
      <app-input
        name="username"
        rules="required"
        :label="$t('fields.username')"
        :binds="{ singleLine: true, prependIcon: 'mdi-account' }"
        v-model="formData.username"
      />
      <app-input
        name="password"
        rules="required"
        :type="viewPassword ? 'text' : 'password'"
        :label="$t('fields.password')"
        :binds="{
          singleLine: true,
          prependIcon: 'mdi-lock',
          appendIcon: viewPassword ? 'mdi-eye' : 'mdi-eye-off'
        }"
        :events="{
          'click:append': () => (viewPassword = !viewPassword)
        }"
        v-model="formData.password"
      />

      <template v-slot:actions>
        <v-btn
          color="primary"
          type="submit"
          width="100%"
          class="mt-2"
          :disabled="loading"
        >
          {{ $t('btn.login') }}
        </v-btn>
      </template>
    </app-form>
    <div class="auth-layout--footer">
      <router-link :to="{ name: 'forget-password' }">
        {{ $t('label.forgetPassword') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import AppForm from '@components/AppForm'
import AppInput from '@components/AppInput'

export default {
  name: 'login',
  components: {
    AppForm,
    AppInput
  },
  data() {
    return {
      formData: {},
      loading: false,
      viewPassword: false
    }
  },
  methods: {
    onSubmit() {
      this.loading = true
      this.$store
        .dispatch('login', this.formData)
        .then(() => {
          this.$router.push({ name: 'home' })
        })
        .catch(() => {
          this.$alert('custom', {
            icon: 'warning',
            title: this.$t('alert.errorLogin.title'),
            text: this.$t('alert.errorLogin.text')
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
